import * as React from "react";

import { Box, Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    backgroundColor: "#42413D",
    color: "#FFF",
    paddingTop: 50,
    paddingBottom: 50,
    "& p": {
      marginTop: 24,
      display: "block",
    },
  },
});

interface IContent {
  backgroundColor?: string;
  children:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactChildren
    | React.ReactChildren[];
}

export const Content = (props: IContent) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Container maxWidth="lg">{props.children}</Container>
    </Box>
  );
};
