import * as React from "react";

import {
  Box,
  Container,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { Link as GatsbyLink } from "gatsby";

const useStyles = makeStyles({
  headerWrapper: {
    backgroundColor: "#FFF",
  },
  header: {
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: { height: 40 },
  navigation: {
    display: "flex",
    "& > a": {
      color: "#42413D",
      marginLeft: 12,
    },
  },
});

export const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Container maxWidth="lg">
        <Box className={classes.header}>
          <Link component={GatsbyLink} to="/">
            <img src="/images/logo.svg" className={classes.logo} />
          </Link>
          <Box className={classes.navigation}>
            <Link component={GatsbyLink} to="/">
              <Typography>Blog</Typography>
            </Link>
            <Link component={GatsbyLink} to="/video">
              <Typography>Video</Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
