export const COLORS = {
  merlin: "#42413D",
  fountainBlue: "#539DBF",
  white: "#FFFFFF",
};

export const ADD_THIS_URL =
  "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6124454bac181933";

export const DOMAIN = "https://thenable.io/";

export const TITLE = "Thenable";
export const TAGLINE = "Deploying the Serverless Stack";
