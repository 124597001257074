import * as React from "react";

import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";

import { COLORS } from "../constants";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: COLORS.merlin,
        },
      },
    },
  },
});

interface ITheme {
  children:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactChildren
    | React.ReactChildren[];
}

export const Theme = (props: ITheme) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        {props.children}
      </>
    </ThemeProvider>
  );
};
